
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Card = makeShortcode("Card");
const CardHeader = makeShortcode("CardHeader");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`FAQ`}</h1>
    <h1>{`คำถามที่พบบ่อย`}</h1>
    <br />
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">ศึกษาอยู่ระดับชั้นไหนจะสมัครเข้าร่วมได้บ้าง</CardHeader>
&nbsp;&nbsp; ค่ายลานเกียร์เปิดโอกาสให้น้อง ๆ ที่เรียนอยู่ชั้น ม.4 - 5 ทุกสายการเรียนหรือ ปวช. ปี 1 - 2 หรือเทียบเท่า ในปีการศึกษา 2567 ครับ
    </Card>
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">ถ้าเคยสมัครแล้วต้องกรอกใหม่ไหม</CardHeader>
      <p>{` `}{` `}{` ด้วยความที่ปีนี้ทางพี่ ๆ ได้มีการปรับปรุงระบบฐานข้อมูลและการลงทะเบียนใหม่ จึงทำให้น้อง ๆ ที่เคยสมัคร หรือ มีเคยกรอกข้อมูลมาแล้วจำเป็นจะต้องกรอกข้อมูลใหม่ครับ`}</p>
    </Card>
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">ค่าสมัครเท่าไร</CardHeader>
      <p>{` `}{` `}{` สมัครฟรีครับ แต่สำหรับน้อง ๆ ที่ผ่านรอบสัมภาษณ์และเป็นผู้มีสิทธิ์เข้าร่วมค่าย จะมีค่าใช้จ่าย 750 บาทครับ`}</p>
    </Card>
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">เงื่อนไขสำหรับใบปพ.1 หรือ ปพ.7 มีอะไรบ้าง</CardHeader>
      <p>{` `}{` `}{` สำหรับน้อง ม.4 ให้ส่งเอกสารในลักษณะนี้อย่างใดอย่างหนึ่ง ใบปพ.1 (หนังสือรับรองผลการเรียน) ที่มีผลการเรียน ม.ต้น 6 ภาคเรียน หรือ ใบปพ.7 (ใบรับรองสถานภาพการศึกษา)`}</p>
      <p>{` `}{` `}{` สำหรับน้อง ม.5 ให้ส่งเอกสารในลักษณะนี้อย่างใดอย่างหนึ่ง ใบปพ.1 (หนังสือรับรองผลการเรียน) ที่มีผลการเรียน ม.ปลาย 2 ภาคเรียน หรือ ใบปพ.7 (ใบรับรองสถานภาพการศึกษา)`}</p>
    </Card>
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">ถ้าขอใบปพ.1 หรือ ปพ.7 ไม่ทันต้องทำอย่างไร</CardHeader>
      <p>{` `}{` `}{` สำหรับน้อง ม.4 กรณีขอใบ ปพ.7 (ใบรับรองสถานภาพการศึกษา) ไม่ทัน น้องสามารถส่งใบ ปพ.7 ที่หมดอายุไปแล้วไม่เกินหนึ่งเดือนนับจากวันรับสมัครวันสุดท้ายได้`}</p>
      <p>{` `}{` `}{` สำหรับน้อง ม.5 กรณีขอใบ ปพ.1 (หนังสือรับรองผลการเรียน) ในเทอมการศึกษาก่อนหน้าไม่ทัน (ปพ.1 ที่มีผลการเรียน ม.ปลาย 2 ภาคเรียน) น้องสามารถส่งใบ ปพ.1 ที่มีผลการเรียน ม.ปลาย 1 ภาคเรียนได้ กรณีขอใบ ปพ.7 (ใบรับรองสถานภาพการศึกษา) ไม่ทัน น้องสามารถส่งใบ ปพ.7 ที่หมดอายุไปแล้วไม่เกินหนึ่งเดือนนับจากวันรับสมัครวันสุดท้ายได้`}</p>
    </Card>
    <Card mdxType="Card">
  <CardHeader mdxType="CardHeader">ค่ายในปีนี้จะเป็นรูปแบบค้างคืนไหม</CardHeader>
      <p>{` `}{` `}{` ค่ายในปีนี้จะจัดในรูปแบบ `}<strong parentName="p">{`ค้างคืน`}</strong>{` หากมีการเปลี่ยนแปลง ทางนิสิตผู้จัดกิจกรรมจะแจ้งให้ทราบโดยเร็วที่สุด`}</p>
    </Card>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;