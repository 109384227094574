const descriptionConstant = [
  {
    src: require("../../assets/images/description/1.jpg")
  },
  {
    src: require("../../assets/images/description/2.jpg")
  },
  {
    src: require("../../assets/images/description/3.jpg")
  },
  {
    src: require("../../assets/images/description/4.jpg")
  },
  {
    src: require("../../assets/images/description/5.jpg")
  },
  {
    src: require("../../assets/images/description/6.jpg")
  },
]

export default descriptionConstant
