
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Card = makeShortcode("Card");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Card mdxType="Card">
      <h1>{`นโยบายความเป็นส่วนตัว`}</h1>
      <br />
      <br />
      <h2>{`นโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์ค่ายลานเกียร์`}</h2>
      <p>{` `}{` `}{` เว็บไซต์ค่ายลานเกียร์เป็นเว็บไซต์ซึ่งใช้และประมวลข้อมูลส่วนบุคคลจากผู้ใช้บริการเว็บไซต์
เพื่อวัตถุประสงค์หลายประการ ซึ่งรวมไปถึง การพัฒนาเว็บไซต์ การคัดเลือกบุคคลเพื่อเข้าค่ายลานเกียร์
เว็บไซต์ค่ายลานเกียร์ตระหนักถึงความสำคัญของการจัดการและคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ ซึ่งเป็นความรับผิดชอบที่สำคัญ
และจำเป็นต่อการรักษาความไว้วางใจของผู้ใช้
เพื่อตอบสนองความรับผิดชอบนี้เราจะปฏิบัติตามกฎหมายและข้อบังคับทั้งหมดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล ("กฎหมายคุ้มครองข้อมูลส่วนบุคคล")
และจะทำงานเพื่อจัดการ และคุ้มครองข้อมูลส่วนบุคคลอย่างเหมาะสมตามนโยบายนี้`}</p>
      <h2>{`คำนิยาม`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`"เรา" หมายถึง ทีมผู้พัฒนาเว็บไซต์ค่ายลานเกียร์และทีมงานผู้เกี่ยวข้องในค่ายลานเกียร์ทุกคน`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`"ผู้ใช้" หรือ "คุณ" หมายถึง ผู้ที่เข้ามาใช้บริการเว็บไซต์ค่ายลานเกียร์ รวมไปถึงผู้สมัครเข้าค่ายลานเกียร์ทุกคน`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`"ข้อมูลส่วนบุคคล" ให้มีความหมายเดียวกับข้อมูลส่วนบุคคลดังที่ปรากฏในพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ("พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล")`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`"เก็บรวบรวม" และ "ใช้" จะมีความหมายเดียวกับ คำว่า "การประมวลผล" ที่กำหนดไว้ใน พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล ซึ่งหมายความว่ารวบรวม บันทึก ยึดถือ เปิดเผย ฯลฯ
ประเภทของข้อมูลส่วนบุคคลของผู้ใช้`}</p>
        </li>
      </ul>
      <p>{` `}{` `}{` เราจะเก็บรวบรวม ใช้ เก็บรักษา และแบ่งปันข้อมูลส่วนบุคคลประเภทต่าง ๆ ของผู้ใช้ ซึ่งต่อไปนี้เป็นตัวอย่างข้อมูลส่วนบุคคลที่เราอาจเก็บรวบรวมจากคุณ
ข้อมูลที่ระบุตัวตน หรือข้อมูลสำหรับการติดต่อ รวมถึง ชื่อ ที่อยู่สำหรับการติดต่อหรือจัดส่งสินค้า อีเมล หมายเลขโทรศัพท์ หมายเลขบัตรประจำตัวประชาชน ชื่อผู้ใช้ หรือ
ข้อมูลที่ใช้ระบุตัวตน หรือสำหรับใช้ติดต่อกับผู้ใช้ ในลักษณะเดียวกันกับข้อมูลที่กล่าวมาข้างต้นข้อมูลการใช้งาน รวมถึง ข้อมูลเกี่ยวกับวิธีที่ผู้ใช้ใช้เว็บไซต์และบริการของเรา
และข้อเสนอแนะจากผู้ใช้เกี่ยวกับประสบการณ์ของผุ้มช้ในการใช้เว็บไซต์ หรือบริการของเรา`}</p>
      <h2>{`การใช้ข้อมูลส่วนบุคคล`}</h2>
      <p>{` `}{` `}{` เว็บไซต์ค่ายลานเกียร์ จะใช้ข้อมูลส่วนบุคคลของผู้ใช้ ตามวัตถุประสงค์ที่แจ้งต่อผู้ใช้ในเวลาที่มีการรวบรวมข้อมูล และเพื่อวัตถุประสงค์ตามที่กำหนดไว้ในนโยบายนี้
("ขอบเขตการใช้งาน") และจะไม่ใช้ข้อมูลเพื่อวัตถุประสงค์อื่นใด เว้นแต่ได้รับความยินยอมจากผู้ใช้ หรือได้รับอนุญาต หรือกระทำไปตามที่กฎหมายได้กำหนดไว้ เพื่อให้เป็นไปตามกฎหมายที่เกี่ยวข้อง
เราอาจใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`เพื่อพัฒนาและส่งเสริมประสบการณ์ในการใช้งานเว็บไซต์ที่ดีขึ้น`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`เพื่อใช้สำหรับการคัดเลือกผู้ใช้ในการเข้าค่ายลานเกียร์`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`เพื่อให้ข้อมูลข่าวสารหรือสถานะของการสมัครเข้าค่ายให้กับผู้ใช้`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`เพื่อให้สามารถปฏิบัติตามข้อผูกพันในสัญญา`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`เพื่อรักษาและปรับปรุงบันทึกข้อมูลส่วนบุคคลของผู้ใช้`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`เพื่อจุดประสงค์อื่นใดที่เกิดขึ้นใหม่ หรือเพิ่มเติมจากวัตถุประสงค์ข้างต้น`}</p>
        </li>
      </ul>
      <p>{` `}{` `}{` และจะมีการแจ้งรายละเอียดอีกครั้ง เมื่อต้องการรวบรวมข้อมูลนี้ โดยจะไม่ใช้ข้อมูลเพื่อวัตถุประสงค์อื่นใด
เว้น แต่ได้รับความยินยอมจากผู้ใช้ หรือได้รับอนุญาต หรือกระทำไปตามที่กฎหมายได้กำหนดไว้
เพื่อให้เป็นไปตาม กฎหมายที่เกี่ยวข้อง โดยนโยบายการใช้ข้อมูลส่วนบุคคลของเรามีวัตถุประสงค์ดังต่อไปนี้`}</p>
      <p>{`ข้อมูลที่เราจะเก็บจากผู้ใช้ มีดังนี้`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`ข้อมูลสำหรับระบุตัวตน เช่น ชื่อ หมายเลขบัตรประชาชน รูปถ่าย`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`ข้อมูลสำหรับการติดต่อ เช่น หมายเลขโทรศัพท์ ที่อยู่สำหรับจัดส่งสินค้า อีเมล`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`ข้อมูลการศึกษา เช่น ระเบียนแสดงผลการเรียน(ปพ.1) ใบรับรองผลการศึกษา(ปพ.7)`}</p>
        </li>
      </ul>
      <p>{` `}{` `}{` การให้ข้อมูลส่วนบุคคลของผู้ใช้ ไม่มีข้อบังคับตามกฎหมาย หรือตามสัญญาว่า ผู้ใช้ต้องให้ข้อมูลส่วนบุคคล ของคุณแก่เรา ดังนั้น การให้ข้อมูลส่วนบุคคลของผูใช้แก่เรา จึงเป็นไปด้วยความสมัครใจของผู้ใช้เอง
อย่างไรก็ตาม หากผู้ใช้ไม่ยินยอมให้ข้อมูลส่วนบุคคลแก่เราตามนโยบายนี้ เว็บไซต์ค่ายลานเกียร์ อาจไม่สามารถปฏิบัติ ตามภาระผูกพันตามสัญญาที่เกี่ยวข้องกับผู้ใช้และอาจให้บริการกับผู้ใช้ได้อย่างเต็มประสิทธิภาพ
หรือ ในกรณีที่ผู้ ใช้ต้องการส่งคำขอลบข้อมูลส่วนตัวออกจากระบบ สามารถส่งมาที่ facebook page : LarnGear Camp`}</p>
      <h2>{`ระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคล`}</h2>
      <p>{` `}{` `}{` ข้อมูลส่วนบุคคลของผู้ใช้จะถูกเก็บไว้เป็นเวลาเท่าที่จำเป็น เพื่อให้เป็นไปตามวัตถุประสงค์ในการประมวลผลของมูลส่วนบุคคลตามที่อธิบายไว้ข้างต้น
เมื่อวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคลเสร็จสมบูรณ์ หรือ ความสัมพันธ์ระหว่างผู้ใช้กับเราสิ้นสุดลง หรือเมื่อข้อมูลการสมัครเข้าค่ายของผู้ใช้มีอายุมากกว่า 1 ปี
เราจะนำข้อมูลส่วนบุคคลของผู้ใช้ออกจากระบบและบันทึกข้อมูลหรือดำเนินการตามขั้นตอนด้วยวิธีใด ๆ เพื่อทำให้ข้อมูลส่วนบุคคลของผู้ใช้กลายเป็นข้อมูลนิรนามอย่างเหมาะสม
เพื่อที่ข้อมูลส่วนบุคคลจะไม่สามารถใช้ระบุตัวตนของคุณได้อีกต่อไป (เว้นแต่ เรามีความจำเป็นจะต้องเก็บข้อมูลของผู้ใช้ เพื่อให้สอดคล้องกับภาระผูกพันทางกฎหมาย)`}</p>
      <p>{` `}{` `}{` เราอาจเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้ไว้ หากข้อมูลส่วนบุคคลของคุณมีความจำเป็นต่อการปฏิบัติ ตามกฎหมายที่บังคับใช้ หรือเราต้องการข้อมูลส่วนบุคคลของผู้ใช้เพื่อก่อตั้ง ใช้
หรือปกป้องสิทธิของผู้ใช้จากการเรียกร้องตามกฎหมาย อย่างไรก็ตามเราจะเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บนพื้นฐานของข้อมูลที่เราจำเป็นต้องรู้เท่านั้น
และจะกระทำภายในขอบเขตที่เป็นไปได้ เราจะจำกัดการประมวลผลข้อมูลส่วนบุคคลของผู้ใช้ตามวัตถุประสงค์ดังกล่าวเท่านั้น`}</p>
      <h2>{`สิทธิของผู้ใช้`}</h2>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`สิทธิในการเพิกถอนความยินยอม`}</strong>{`: หากผู้ใช้ได้ให้ความยินยอม ในการรวบรวม การประมวลผล และการใช้ข้อมูลส่วนบุคคลในบางเรื่อง
ผู้ใช้สามารถเพิกถอนความยินยอมนี้ได้ทุกเมื่อ โดยการเพิกถอนความยินยอมดังกล่าวจะมีผลหลังจากที่เราได้รับการเพิกถอนความยินยอมจากคุณ
อย่างไรก็ตาม การเพิกถอนความยินยอมดังกล่าวจะไม่ส่งผลต่อความถูกต้องตามกฎหมายในการประมวลผลของเรา ก่อนมีการเพิกถอนความยินยอมจากคุณ
โปรดติดต่อเรา หากคุณต้องการจะเพิกถอนความยินยอมของคุณ`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`สิทธิในการร้องขอการเข้าถึงข้อมูลส่วนบุคคลของคุณ`}</strong>{`: ผู้ใช้มีสิทธิได้รับคำยืนยันจากเราว่าข้อมูลส่วนบุคคลที่เกี่ยวข้องกับผู้ใช้กำลังถูกประมวลผลหรือไม่
และในกรณีดังกล่าว ผู้ใช้มีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณ การเข้าถึงข้อมูลส่วนบุคคลนี้
รวมถึงและโดยเฉพาะอย่างยิ่งวัตถุประสงค์ในการประมวลผล หมวดหมู่ของข้อมูลส่วนบุคคลที่เกี่ยวข้อง
และผู้รับข้อมูลส่วนบุคคล หรือประเภทของผู้รับข้อมูลส่วนบุคคลที่ได้รับหรือจะเปิดเผยข้อมูลส่วนบุคคล อย่างไรก็ตาม นี่ไม่ใช่สิทธิเด็ดขาด
และอาจมีการจำกัดสิทธิในการเข้าถึงของข้อมูลส่วนบุคคลของผู้ใช้หากการใช้สิทธิของผู้ใช้อาจทำให้ผู้อื่นได้รับความเสียหาย
ผู้ใช้มีสิทธิได้รับสำเนาข้อมูลส่วนบุคคลของคุณที่เราใช้ประมวลผลโดยไม่เสียค่าใช้จ่าย`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`สิทธิในการขอแก้ไขข้อมูลส่วนบุคคล`}</strong>{`: ผู้ใช้มีสิทธิขอให้เราแก้ไขข้อมูลส่วนบุคคลของผู้ใช้ที่ไม่ถูกต้อง ขึ้นอยู่กับวัตถุประสงค์ของการประมวลผล
ผู้ใช้มีสิทธิที่จะกรอกข้อมูลส่วนบุคคลที่ไม่สมบูรณ์ รวมถึง การให้คำชี้แจงเพิ่มเติม`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`สิทธิในการขอให้ลบข้อมูลส่วนบุคคล`}</strong>{`: ในบางกรณี ผู้ใช้มีสิทธิขอให้เราลบข้อมูลส่วนบุคคลที่เกี่ยวข้องกับผู้ใช้ และเราอาจดำเนินการลบข้อมูลส่วนบุคคลดังกล่าว`}</p>
        </li>
      </ul>
      <h2>{`การทบทวนและปรับปรุงการจัดการข้อมูลส่วนบุคคล`}</h2>
      <p>{` `}{` `}{` นโยบายนี้อาจได้รับการแก้ไขเป็นครั้งคราวตามความจำเป็นเพื่อสะท้อนถึงการปรับปรุงอย่างต่อเนื่องของมาตรการคุ้มครองข้อมูลส่วนบุคคลของเรา
และเพื่อให้เป็นไปตามการเปลี่ยนแปลงใด ๆ ต่อกฎหมายคุ้มครองข้อมูลส่วนบุคคล ดังนั้น นโยบายนี้อาจเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า หากผู้ใช้ไม่ติดต่อเรา
เราจะถือว่าผู้ใช้ยอมรับการเปลี่ยนแปลงดังกล่าว ดังนั้น เราขอแนะนำให้ผู้ใช้เยี่ยมชมเว็บไซต์ของเราเป็นครั้งคราว เพื่อให้แน่ใจว่าผู้ใช้รับทราบนโยบายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลล่าสุดของเรา`}</p>
    </Card>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;